

.swiper-container-bottom{
    position:relative;
    max-width: 40vw;
    padding: 20px;
    margin-top: 48px;
}

.swiper-container-bottom > .swiper-slide__content {
    position:absolute;
  }
  
  .swiper-slide {
    max-height: 1100px;
    width: 350px;
    display:flex;
    align-items: center;
    justify-content:center;
    margin-bottom: 30px;
  }
  .swiper-slide__content {
    max-height: 1200px;
  }
  
.imagecontainer{
    min-width: 300px;
    max-width: 400px;
    min-height: 500px;
    max-height: 1200px;
    
}

img{
    max-width: 100%;
    height: 100%;
} 

/* .textcursor {
  font-size: 1px;
} */



@media screen and (max-width: 1000px) {
  .swiper-container{
    position:relative;
    max-width: 90vw;
    padding: 20px;
    margin-top: 48px;
}
  
  .imgcontainer{
    max-width: 50vw;
    max-height: 50vw;
  }
  img{
    width: 800px;
  } 
  .swiper-slide {
    max-height: 1100px;
    min-width: 70%;
    max-width: 100%;
    min-height: 50%;
    display:flex;
  }
} 
